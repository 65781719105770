import { i18n } from '@/utils/i18n'

export default {
    data() {
        return {
            errorText: '',
            submitting: false,
            loading: false,
            loadingTitle: 'Loading...',
            stepTitleNames: i18n.t('progressTitle.origination'),
        }
    },
}
