<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="$t('pages.origination.welcome.loading')"
        :error-text="errorText"
    >
        <div class="text-center">
            <img
                src="../../assets/images/origination/Celebration.svg"
                width="200"
                class="mb-3"
                alt="Celebration"
            >
        </div>

        <h5
            class="text-center fw-bold"
            v-html="$t('pages.origination.welcome.title')"
        />

        <div v-if="isFlaggedAsFraud">
            <div class="mt-2">
                <span v-html="$t('pages.origination.welcome.fraudReview')" />
            </div>
            <div class="mt-2">
                <span v-html="$t('pages.origination.welcome.fraudReviewAdditional')" />
            </div>
        </div>

        <div v-else>
            <div class="mt-2">
                <span v-html="$t('pages.origination.welcome.timeLine')" />
            </div>

            <div
                v-if="extraStateInfo"
                class="mt-2"
            >
                <span v-html="extraStateInfo" />
            </div>

            <div
                v-show="payItForwardLink"
                class="pif-background text-center useLoraFont mt-2"
            >
                <img
                    src="../../assets/images/origination/heart-outline.svg"
                    width="43"
                    class="mb-2"
                    alt="love"
                >
                <div class="pif-title mb-2">
                    Invite & Share the Love
                </div>
                <p
                    class="mb-2"
                    v-html="$t('pages.origination.welcome.pifMessage', { maxAmount: toFormattedUSDNoCents(this.payItForwardMaxAmount) })"
                />
                <div class="wrap">
                    <img
                        src="../../assets/images/origination/CardSharetheLove.svg"
                        width="173"
                        class="mb-2 center-in-wrap"
                        alt="aven card"
                    >
                    <p class="aven-pif-card-amount">
                        {{ toFormattedUSDNoCents(this.payItForwardMaxAmount) }}
                    </p>
                </div>

                <p
                    class="my-2"
                    v-html="$t('pages.origination.welcome.pifMessageAdditional')"
                >
                    />
                </p>
                <div v-show="isMobile">
                    <a
                        class="btn btn-send-sms"
                        @click="shareViaText"
                    > Share via Text Message </a>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <hr class="p-5">
                        <div class="p-2 fw-normal">
                            or
                        </div>
                        <hr class="p-5">
                    </div>
                </div>

                <p>
                    {{ $t('pages.origination.welcome.shareLinkMessage') }}
                </p>

                <div
                    class="btn-link-copy"
                    @click="copyLink"
                >
                    {{ copyButtonDisplayString }}
                </div>
            </div>
            <div class="mt-4">
                <div class="fw-bold">
                    Next Steps
                </div>
                <ul class="list-unstyled next-steps-list">
                    <li>
                        <a
                            :href="baseUrl + '/app'"
                            class="text-primary text-underline mt-2"
                        >Download</a> the Aven App
                    </li>
                    <li>Activate your card when it arrives</li>
                    <li>Use your Visa Aven Card</li>
                    <li>Schedule a Balance Transfer</li>
                    <li>Set up AutoPay</li>
                </ul>
            </div>
            <div class="d-grid">
                <a
                    :href="baseUrl + '/app'"
                    class="btn btn-primary mt-4"
                > Download The App</a>
            </div>
        </div>

        <div>
            <p class="text-muted mt-4">
                <i18n path="pages.origination.welcome.nextSteps">
                    <template #linkText>
                        <a
                            v-html="$t('pages.origination.welcome.nextStepsLinkText')"
                            href="#"
                            @click="getDocument('CombinedNotarizedDocs')"
                        />
                    </template>
                </i18n>
            </p>
            <p class="text-muted mt-2">
                If you have any questions, please email or text us on our <a :href="baseUrl + '/support'">support page</a>.
            </p>
        </div>

        <template
            #sidebar
            v-if="payItForwardLink && !isFlaggedAsFraud"
        >
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
            <p class="text-muted small">
                *Payment of the signing bonus to the Recipient is contingent upon the final acceptance of the deed of trust by the applicable recorder’s office and the activation of the Recipient’s
                Aven Card. The signing bonus will be issued to the Recipient in the form of a statement credit within [5] business days of the activation of their Aven Card.
            </p>
            <p class="text-muted small">
                If you have any questions, please email or text us on our support page.
            </p>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { mixins } from '@/utils/mixins'
    import { getDocusignLegalDownload, postIsAccountFlaggedAsFraud, getStatePageModules } from '@/services/api'
    import originationMixin from '@/mixins/originationMixin'
    import format from '@/mixins/format'
    import { logger } from '@/utils/logger'
    import { isIOS, isIOSSafari, isMobile } from '@/utils/parseUserAgents'
    import { i18n } from '@/utils/i18n'
    import { getSecondarySessionLink } from '@/services/schedulingApi'
    import url from 'url'
    import { getPayItForwardInfo } from '@/services/payItForward'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'

    export default {
        components: {
            HelpListItem,
            'onboarding-layout': OnboardingLayout,
        },
        mixins: [mixins, originationMixin, format],
        computed: {
            smsMessage: function () {
                const temp = this.$t('pages.origination.welcome.smsMessage', { maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount), pifLink: this.payItForwardLink }).toString()
                return temp
            },
            copyButtonDisplayString: function () {
                if (!this.payItForwardLink) {
                    return ''
                }

                if (this.isLinkCopied) {
                    return this.$t('pages.origination.welcome.pifLinkCopied')
                } else {
                    const pifURL = new URL(this.payItForwardLink)
                    return `${pifURL.host}${pifURL.pathname}`
                }
            },
            extraStateInfo: function () {
                if (Array.isArray(this.stateModules)) {
                    const moduleWelcomeExtraInfo = this.stateModules.find((stateModule) => {
                        return stateModule.type === 'WelcomeExtraInfo'
                    })

                    if (moduleWelcomeExtraInfo) {
                        return moduleWelcomeExtraInfo.data.extraInfoText
                    }
                }

                return ''
            },
        },
        data() {
            return {
                loading: true,
                accounts: [],
                baseUrl: process.env.VUE_APP_ORIGINATION_BASE_URL,
                payItForwardLink: '',
                payItForwardCode: '',
                payItForwardMaxAmount: null,
                isLinkCopied: false,
                isMobile: isMobile(),
                isIOS: isIOS(),
                isFlaggedAsFraud: false,
                stateModules: null,
            }
        },
        mounted: async function () {
            await Promise.all([this.loadData(), this.getStateModuleData(), this.isAccountFraud()])
        },
        methods: {
            getStateModuleData: async function () {
                const stateModulesResponse = await getStatePageModules('WelcomeScreen')
                this.stateModules = (stateModulesResponse.data.payload && stateModulesResponse.data.payload.modules) || []
            },
            isAccountFraud: async function () {
                try {
                    this.loading = true
                    const loanApplicationId = appSessionStorage.getItem(localStorageKey.loanApplicationId)

                    logger.log(`Fetching fraud status of LA: ${loanApplicationId}`)
                    const response = await postIsAccountFlaggedAsFraud(loanApplicationId)
                    this.isFlaggedAsFraud = response.data.payload.isFlaggedAsFraudulent

                    logger.log(`Set isFlaggedAsFraud to ${this.isFlaggedAsFraud}`)
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loading = false
                }
            },
            loadData: async function () {
                try {
                    const secondaryLinkResp = await getSecondarySessionLink()

                    // Are we going to stay here, or redirect?
                    if (secondaryLinkResp.data.success) {
                        logger.log('Looks like this user has a secondary who wants to sign, redirecting at warp speed...')
                        const sessionLink = secondaryLinkResp.data.payload
                        const parsedUrl = url.parse(sessionLink)
                        logger.log(`Redirecting to ${parsedUrl.path}`)
                        return await this.$router.replace({ path: parsedUrl.path })
                    }

                    this.$logEvent('view_welcome')

                    logger.log('Looks like this user is not a primary and/or did not want to sign at the same time with their secondary, staying put.')
                    logger.log('getting pif data')

                    const response = await getPayItForwardInfo()
                    logger.log(`received ${JSON.stringify(response.data.payload)}`)

                    if (response.data.success) {
                        this.payItForwardMaxAmount = response.data.payload.maxAmount
                        this.payItForwardLink = response.data.payload.shareLink
                        this.payItForwardCode = response.data.payload.pifCode
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loading = false
                }
            },
            getDocument: async function (docType) {
                this.loading = true
                try {
                    const response = await getDocusignLegalDownload(docType)
                    const file = new Blob([response.data], { type: 'application/pdf' })
                    const fileURL = URL.createObjectURL(file)
                    if (isIOSSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                } catch (e) {
                    logger.error(`failed to open pdfs document due to error`, null /* event */, e)
                    this.errorText = i18n.tc('global.errors.generic')
                }
                this.loading = false
            },
            shareViaText: function () {
                window.logEvent('click_button_welcome_pif_share_via_text_message', { message: this.smsMessage, pifAmount: this.payItForwardMaxAmount })
                window.location.href = this.isIOS ? encodeURI(`sms://&body=${this.smsMessage}`) : `sms:?body=${encodeURI(this.smsMessage)}`
            },
            copyLink: function () {
                this.copyToClipboard(this.payItForwardLink)
            },
            copyToClipboard: function (str) {
                const el = document.createElement('textarea')
                el.value = str
                document.body?.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body?.removeChild(el)
                this.isLinkCopied = true
                setTimeout(() => (this.isLinkCopied = false), 2000)
                window.logEvent('click_button_welcome_pif_copy_link', { pifLink: this.payItForwardLink, pifAmount: this.payItForwardMaxAmount })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/welcome';
    @import '../../styles/variables';

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-Bold.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-BoldItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
    }

    .useLoraFont {
        font-family: 'Lora';
        font-weight: bold;

        p {
            font-weight: normal;
        }
    }

    .pif-background {
        border-radius: 16px;
        padding: 2rem 1.5rem 2rem 1.5rem;
        background-color: #fadfce;
        border-color: #fadfce;
        justify-content: center;
    }

    .pif-title {
        font-size: 1.25rem;
        font-style: italic;
    }

    .btn-send-sms {
        height: 54px;
        width: 100%;
        font-family: 'Lora', serif;
        font-style: italic;
        font-size: 1rem;
        border-width: 1px;
        border-color: $black;
        background-color: #fadfce;
        border-radius: 27px;
    }

    .wrap {
        width: 100%;
        height: 110px;
        color: white;
        text-align: center;
        line-height: 140px;
        position: relative;
    }
    .center-in-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .aven-pif-card-amount {
        position: absolute;
        color: #c99134;
        font-weight: bold;
        top: 2px;
        left: 50%;
        transform: translate(36px, 0);
        font-family: 'gtamerica';
    }

    hr {
        height: 1px;
        width: 100%;
        border: 0;
        border-top: 1px solid black;
        margin: 0;
        padding: 0 !important;
    }

    .btn-link-copy {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 8px;
        align-content: center;
        background-color: $white;
        font-weight: normal;
        font-color: $black;
        border-width: 0;
    }
</style>
